.sid-test-mode-flag {
  background: #e12046;
  color: white;
  padding: 10px;
  text-transform: uppercase;
}

.sid-test-mode-flag__link {
  color: white;
  padding-left: 5px;
  text-transform: lowercase;

  &:hover {
    color: $light-gray;
  }
}
