/**
 * TODO - preamble
 *
 * These styles follow BEM naming convention with prefixes for some of the layers.
 * Base rules are assumed to be provided by the page SheerID is being used within.
 *
 * Class naming structure:
 *     block__element--modifier
 *
 * Prefixes:
 *     sid = sheerid global prefix to prevent classname collisions.
 *     l = layout rule
 *     m = module rule
 *     h = helper rule
 *
 * No grandchildren
 */
@import "./sheerid-variables";
@import "./sheerid-base-rules";
@import "./sheerid-helpers";
@import "./sheerid-layout";
@import "./sheerid-iframe";
@import "../components/FormFields/FormFields";
@import "../components/DateComponent/DateComponent";
@import "../components/FormFields/SSN/SSN";
@import "../components/FormFields/DischargeDate/DischargeDateComponent";
@import "../components/FormFields/Country/CountryComponent";
@import "../components/FormFields/ChangeLocale/ChangeLocaleComponent";
@import "../components/HowDoesVerifyingWork/HowDoesVerifyingWork";
@import "../components/Button/ButtonComponent";
@import "../components/CopyToClipboard/CopyToClipboard";
@import "../components/FormWrapper/FormWrapperComponent";
@import "../components/LoadingScreen/LoadingScreenComponent";
@import "../components/LoadingSpinner/LoadingSpinnerComponent";
@import "../components/StepDocUpload/StepDocUploadComponent";
@import "../components/StepSMSLoop/StepSMSLoopComponent";
@import "../components/StepSuccess/StepSuccessComponent";
@import "../components/StepPending/ReviewPendingComponent";
@import "../components/StepCompleteAuthentication/StepCompleteAuthentication";
@import "../components/OrganizationList/OrganizationListComponent";
@import "../components/FormInputs/InputSelect/InputSelectList/InputSelectListComponent";
@import "../components/FormInputs/InputSelect/InputSelectButton/InputSelectButtonComponent";
@import "../components/FormInputs/Select/SelectList/SelectListComponent";
@import "../components/FormInputs/Select/SelectButton/SelectButtonComponent";
@import "../components/AcceptableUploadsComponent/AcceptableUploadsComponent";
@import "../components/UploadInfoComponent/UploadInfoComponent";
@import "../components/TestModeFlag/TestModeFlagComponent";
@import "../components/FormFields/PhoneNumber/PhoneNumber";
@import "../components/LogoComponent/LogoComponent";
@import "../components/StepIDCheckLoop/StepIDCheckLoopComponent";
@import "../components/StepIDCheckLoop/IDCheckLoop";
@import "../components/StepIDCheckLoop/StepCard/StepCard";
@import "../components/StepIDCheckLoop/DocTypeCard/DocTypeCard";
@import "../components/ExpandingSection/ExpandingSection";
@import "../components/Layout/Layout";

// ====================================== Blocks =================================
.sid-header {
  &__title {
    color: var(--sheerid-form-h1-font-color);
    font-weight: 700;
    margin: 1.25rem 0 1rem;
    text-align: center;
  }

  &__title > h1,
  &__title {
    font-size: var(--sheerid-font-size-title);
    line-height: var(--sheerid-heading-line-height);
  }

  &__subtitle {
    font-size: var(--sheerid-font-size-body);
    font-weight: normal;
  }
}

.sid-countdown {
  color: $sheer-blue;
  font-size: 3rem;
  margin-top: $mediumPadAmt;
  text-align: center;
  text-rendering: optimizeLegibility;
}

.sid-header__subtitle--error {
  color: $red;
}

.sid-footer {
  align-items: center;
  display: flex;
}

.sid-footer__powered-by-container {
  display: flex;
}

.sid-footer__powered-by-container--stacked {
  display: block;
}

.sid-footer__shield {
  margin-right: $gutterWidth * 2;
}

.sid-footer__text {
  margin-top: -1em;
  max-height: 1.4rem;
  overflow: auto;
  padding: 0.5em;
}

.sid-footer__text:focus-within {
  max-height: none;
}

.sid-link,
.sid-link:link,
.sid-link:visited,
.sid-link:hover,
.sid-link:active {
  color: var(--sheerid-form-link-color);
  cursor: pointer;
  text-decoration: none;
}

.sid-faq,
.sid-privacy-policy {
  text-decoration: underline !important;
}

// List item
.sid-li {
  margin-bottom: 5px; // TODO use something from sheerid-variables.scss
}

.sid-reward-code-wrap {
  font-size: var(--sheerid-font-size-title);
  font-weight: 800;

  // TODO split out into layout rule:
  height: 200px;
}

.sid-names {
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.sid-names > * {
  box-sizing: border-box;
  width: 50%;
}

.sid-names > *:first-child {
  .sid-ltr & {
    margin-right: calc($gutterWidth / 2);
  }

  .sid-rtl & {
    margin-left: calc($gutterWidth / 2);
  }
}

.sid-names > *:last-child {
  .sid-ltr & {
    margin-left: calc($gutterWidth / 2);
  }

  .sid-rtl & {
    margin-right: calc($gutterWidth / 2);
  }
}

.sid-names__first-name {
  margin-right: $gutterWidth;
}

@mixin columnal-name-fields {
  .sid-names {
    flex-direction: column;
  }

  .sid-names > *:first-child {
    .sid-ltr & {
      margin-left: 0;
      margin-right: 0;
    }

    .sid-rtl & {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .sid-names > *:last-child {
    margin-left: 0;
    margin-right: 0;
  }

  .sid-first-name {
    width: 100%;
  }

  .sid-last-name {
    width: 100%;
  }
}

.sid-left-align {
  @include columnal-name-fields;

  .sid-field__label-with-explanation {
    display: block;

    & > * {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

@media (width <= 374px) {
  @include columnal-name-fields;
}
