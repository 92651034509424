.sid-to-clipboard {
  display: inline-block;
  position: relative;

  &__clickable-text {
    position: relative;
    z-index: $base-z-index;
  }

  &__notification-text {
    color: $blue-link;
    font-size: var(--sheerid-font-size-small);
    left: 0;
    position: absolute;
    text-align: center;
    transition: all ease 0.15s;
    width: 100%;
    z-index: $base-z-index - 1;

    &--hidden {
      opacity: 0;
      top: var(--sheerid-font-size-small);
    }

    &--hidden span {
      display: none;
    }

    &--visible {
      opacity: 1;
      top: calc(var(--sheerid-font-size-small) * -1.5);
    }
  }
}
