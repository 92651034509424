.sid-l-container {
  // Container
  & .onfido-sdk-ui-Modal-inner {
    background-color: transparent;
    border-width: 0;
    font-size: var(--sheerid-font-size-body);
    height: 33rem;
    position: static;
    width: auto;
  }

  // Header
  & .onfido-sdk-ui-PageTitle-titleWrapper {
    margin: 0 0 15px;

    // Title text
    & .onfido-sdk-ui-PageTitle-titleSpan {
      color: $dark-blue-gray;
      font-size: var(--sheerid-font-size-subtitle);
    }

    // Subtitle text
    & .onfido-sdk-ui-PageTitle-subTitle {
      font-size: var(--sheerid-font-size-body);
      font-weight: 400;
      line-height: calc(var(--sheerid-font-size-body) * 0.9375);
    }
  }

  // Navigation bar
  & .onfido-sdk-ui-NavigationBar-navigation {
    padding: 0;
  }

  // Button styles
  & .onfido-sdk-ui-Theme-root {
    & .ods-button.-action {
      &--primary {
        background-color: $black;
        border-color: $black;
        border-radius: 0;
        color: $white;
        font-size: var(--sheerid-font-size-subtitle);

        &:hover,
        &:active {
          background-color: $darker-gray;
          border-color: $darker-gray;
          color: $white;
        }
      }

      &--secondary {
        background-color: $light-gray;
        border-color: $light-gray;
        border-radius: 0;
        color: $blue-gray;
        font-size: var(--sheerid-font-size-subtitle);

        &:hover,
        &:active {
          background-color: $gray;
          border-color: $gray;
        }
      }
    }
  }

  & .onfido-sdk-ui-Welcome-instructions > ol li::marker {
    color: $black;
  }

  // Link styles
  & .onfido-sdk-ui-Theme-link,
  & .onfido-sdk-ui-Theme-textButton {
    border: none;
    color: $blue-link;
    cursor: pointer;
    text-decoration: none;

    &:hover,
    &:active {
      background: transparent !important;
      border: none !important;
      color: $blue-link !important;
      cursor: pointer;
      text-decoration: underline !important;
    }
  }

  // Buttons within "Send to Phone" flow
  & .onfido-sdk-ui-crossDevice-CrossDeviceLink-btn {
    height: auto;
  }

  // QR Code container
  & .onfido-sdk-ui-crossDevice-CrossDeviceLink-qrCodeContainer {
    height: 9rem;
  }

  // QR Code background
  & .onfido-sdk-ui-crossDevice-CrossDeviceLink-qrCodeBackground {
    height: 100%;
    padding: 0;
    width: auto;
  }
}

.sid-identity-check-doc-types {
  background-color: $light-gray;
  padding: 1rem;

  &__icon {
    height: 3rem;
    width: 3rem;
  }
}
