@import "./sheerid-variables";

.sid-inline-iframe {
  border: 0;
  height: 100%;
  min-width: 32rem;
}

.sid-modal {
  &__wrapper {
    background: $white;
    border-radius: 5px;
    box-shadow: #989898 10px 10px 15px;
    left: 50%;
    max-height: calc(100% - 80px);
    opacity: 0;
    outline: none;
    overflow-y: auto;
    position: fixed;
    top: 50%;
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    transition: opacity 0.3s linear;
    width: 32rem;
    z-index: 9998;

    &.fade-in {
      opacity: 1;
    }
  }

  &__iframe {
    border: 0;
    display: block;
    height: 100%;
    min-width: 250px;
    width: 100%;
  }

  &__close-button {
    align-items: center;
    background: none;
    border: none;
    color: inherit;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    position: absolute;
    right: 10px;
    text-decoration: none;
    top: 10px;
    z-index: 9999;
  }

  &__close-icon {
    background-image: url("https://services.sheerid.com/jsapi/img/btn1.png");
    background-repeat: no-repeat;
    filter: brightness(25%);
    height: 20px;
    width: 20px;
  }

  &__close-text {
    color: $black;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: var(--sheerid-font-size-body);
    margin-right: 5px;
  }

  &__overlay {
    background-color: #b3b3b3;
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    transition: opacity 0.3s linear;
    width: 100%;
    z-index: 9997;

    &.fade-in {
      opacity: 0.75;
    }
  }
}

@media (width <= 620px) {
  .sid-modal__wrapper {
    max-width: calc(100vw - 1rem);
  }
}

@media (width <= 32rem) {
  // HC-420: separating this breakpoint so the iframe embed doesn't attempt to
  // take over the full width until it's smaller than it's standard width. It's
  // kind of awkward, but when making this change I found a case where a
  // customer was relying on a width to provide structure to an absolute
  // element. And we do need the form to shrink on smaller screens.
  .sid-inline-iframe {
    min-width: calc(100vw - 1rem);
    width: 100%;
  }
}
