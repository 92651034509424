@import "~react-phone-input-2/lib/plain.css";

.sid-phone-number__wrapper {
  .form-control {
    font-size: var(--sheerid-font-size-body);
    width: inherit;
  }
}

.react-tel-input {
  font-size: inherit;
}

.react-tel-input .form-control {
  border: 1px solid #acb4c6;
  box-sizing: border-box;
  font-size: inherit;
  height: initial;
  line-height: normal;
  padding: var(--sheerid-font-size-medium) var(--sheerid-font-size-medium)
    var(--sheerid-font-size-medium) calc(var(--sheerid-font-size-medium) * 4.5);
}

.react-tel-input .selected-flag {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: var(--sheerid-font-size-medium);
  width: calc(var(--sheerid-font-size-medium) * 3.5);
}

.react-tel-input .selected-flag .flag {
  margin-top: initial;
  position: relative;
  top: initial;
}
