.sid-id-check-doc-type-card {
  align-items: center;
  background-color: $white;
  border: 1px solid $light-blue-gray;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1rem auto;
  padding: 1rem;
  width: 100%;

  &:last-of-type {
    margin-bottom: 0;
  }

  &:hover {
    box-shadow: 0 0 0 2px $blue-gray;
  }

  &--highlighted {
    border: 1px solid $blue-gray;
  }

  &__content {
    align-items: center;
    display: flex;
    flex-direction: row;
  }

  &__header {
    padding-right: 16px;
  }

  &__icon {
    height: 3rem;
    margin-bottom: 0.5rem;
    width: 3rem;
  }

  &__label {
    text-align: left;
  }

  &__title {
    font-size: var(--sheerid-font-size-small);
    font-weight: 700;
  }

  &__subtitle {
    color: $dark-gray;
  }
}
