.sid-incorrect-number {
  margin-top: $smallMarginAmt;
}

.sid-incorrect-number__refresh-link {
  color: $sheer-blue;
}

.sid-sms-code-id-wrapper {
  margin: 0 auto;
  max-width: 100px;
}
