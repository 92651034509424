.sid-pending-red-box {
  background: white;
  border: 1px solid #e12046;
  color: #e12046;
  margin-top: 1.5rem;
  text-align: start;

  ul {
    padding-inline-end: 20px;
    padding-inline-start: 30px;
  }
}
