.sid-date__inputs {
  display: flex;
}

.sid-date__month,
.sid-date__day {
  .sid-ltr & {
    margin-right: $gutterWidth;
  }

  .sid-rtl & {
    margin-left: $gutterWidth;
  }
}

.sid-date__month,
.sid-date__day,
.sid-date__year {
  box-sizing: content-box;
}

.sid-date__month {
  flex: 4 1 10rem;
  max-width: 14rem;
}

.sid-date__day {
  flex: 0 1 2rem;
}

.sid-date__year {
  flex: 0 1 4rem;
}

@media (width <= 300px) {
  .sid-date__inputs {
    display: flex;
    flex-direction: column;
  }

  .sid-date__month {
    width: 100%;
  }

  .sid-date__day,
  .sid-date__year {
    box-sizing: border-box;
    margin-top: 12px;
    width: 100%;
  }

  .sid-date__month,
  .sid-date__day {
    margin-right: 0;
  }
}
