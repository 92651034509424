.sid-input-select-button {
  background-color: transparent;
  border: none;
  height: 100%;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 35px;

  .sid-ltr & {
    right: 0;
  }

  .sid-rtl & {
    left: 0;
  }
}

.sid-input-select-button:disabled {
  cursor: not-allowed;
}
