.sid-barcode {
  max-width: 50%;
}

.sid-reward-container {
  display: flex;
  flex-direction: column;
}

.sid-remember-me-footer-container {
  border: 1px solid $light-gray;
  border-radius: 4px;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 10%), 0 4px 6px -4px rgb(0 0 0 / 10%);
  color: $dark-blue-gray;
  margin-top: 30px;
  padding: 15px;
  text-align: center;
}

.sid-remember-me-title {
  font-size: var(--sheerid-font-size-subtitle);
  font-weight: 700;
  padding-bottom: 16px;
  padding-top: 18px;
}

.sid-remember-me-description {
  padding-bottom: 40px;
}

.sid-remember-me-redirect-btn {
  background-color: transparent;
  border: 2px solid $dark-blue-gray;
  border-radius: 4px;
  font-weight: bold;
}

.sid-remember-me-redirect-btn:hover {
  background-color: $dark-blue-gray;
  color: #f1f5f9;
}
