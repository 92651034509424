.sid-expanding-section {
  border: 1px solid $gray;
  border-radius: 0.25rem;

  &__title {
    align-items: center;
    background: none;
    border: none;
    color: $dark-blue-gray;
    cursor: pointer;
    display: flex;
    font-weight: bold;
    justify-content: space-between;
    padding: 1rem;
    text-align: start;
    width: 100%;
  }

  &__indicator {
    border: 0 solid $gray;
    border-width: 2px 2px 0 0;
    height: 0.8rem;
    margin-inline-start: 1rem;
    position: relative;
    top: -0.3rem;
    transform: rotate(135deg);
    width: 0.8rem;
  }

  &__indicator--state-open {
    top: 0.3rem;
    transform: rotate(-45deg);
  }

  &__children {
    background: $light-gray;
    border-radius: 0 0 0.25rem 0.25rem;
    border-top: 1px solid $gray;
    padding: 0.5rem 1rem;
  }
}
