.sid-btn {
  background-color: var(--sheerid-form-button-color);
  border: none;
  box-sizing: border-box;
  display: block;
  font-size: var(--sheerid-font-size-body);
  margin: 0;
  padding: var(--sheerid-font-size-medium);
  text-align: center;
  text-decoration: none;
  transition: all 0.2s;
  width: 100%;
}

.sid-btn--dark {
  background-color: var(--sheerid-form-button-color);
  color: var(--sheerid-form-button-font-color);

  &:hover {
    background-color: $darker-gray;
  }
}

.sid-btn--light {
  background-color: $light-gray;
  color: $blue-gray;

  &:hover {
    background-color: $gray;
  }
}

.sid-btn:hover {
  cursor: pointer;
}

.sid-btn--disabled-like {
  background-color: $darker-gray;
  cursor: not-allowed !important;
}

.sid-btn-light {
  background-color: $white;
  border: 2px solid $light-blue-gray;
  color: $blue-gray;
}

.sid-btn-light:hover {
  background-color: $white;
  border-color: $blue-gray;
  color: $dark-blue-gray;
}

.sid-btn:disabled {
  background-color: $darker-gray;
  cursor: not-allowed;
}
