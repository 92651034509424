.sid-change-locale {
  display: flex;
  font-size: var(--sheerid-font-size-small);
  font-weight: 400;
  margin-top: $mediumPadAmt;
  white-space: nowrap;
}

.sid-change-locale__input {
  background-color: inherit;
  border: none;
  box-sizing: border-box;
  color: $sheer-blue;
  cursor: pointer;
  padding: 0 5px;
  transition: all 0.15s;
}
