@import "./sheerid-variables";

// ====================================== Layout =================================
.sid-l-horz-center {
  justify-content: center;
  margin: 0 auto;
  text-align: center;
}

// For children that are display-block
.sid-l-horz-center > * {
  margin-left: auto;
  margin-right: auto;
}

.sid-l-container,
.sid-l-container--wide,
.sid-l-container--narrow {
  // TODO move to layout rules for container
  margin: 0 auto;
}

.sid-l-container {
  max-width: 36rem;
  padding: 15px;
}

.sid-l-container--narrow {
  max-width: 300px;
}

.sid-l-container--wide {
  max-width: 800px;
}

.sid-l-container--extra-wide {
  max-width: 1366px;
}

.sid-l-space-top-xl {
  margin-top: $xlPadAmt;
}

.sid-l-space-top-lg {
  margin-top: $largePadAmt;
}

.sid-l-space-top-md {
  margin-top: $mediumPadAmt;
}

.sid-l-space-top-sm {
  margin-top: $smallPadAmt;
}

.sid-l-space-btm-sm {
  margin-bottom: $smallPadAmt;
}

.sid-l-space-btm-md {
  margin-bottom: $mediumPadAmt;
}

.sid-l-space-btm-lg {
  margin-bottom: $largePadAmt;
}

.sid-l-space-left-sm {
  margin-left: $gutterWidth;
}

.sid-l-full-width {
  width: 100%;
}

.sid-l-half-width {
  width: 50%;
}

.sid-l-two-thirds-width {
  width: 66%;
}

.sid-thirds {
  display: flex;
}

.sid-thirds > * {
  box-sizing: border-box;
  margin-right: $gutterWidth;
  width: 33.3333%;
}

.sid-thirds > *:last-child {
  margin-right: none;
}

.sid-l-lead-image {
  height: 70px; // Height so there's no fouc
  width: 70px;
}

.sid-l-position {
  position: relative;
}

.sid-l-absolute-center {
  inset: 0;
  margin: auto;
  position: absolute;
}

.sid-rtl {
  direction: rtl;
}

@media screen and (width <= 374px) {
  .sid-l-container {
    box-sizing: border-box;
    width: 100%;
  }
}
