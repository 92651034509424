.sid-select-button {
  background-color: transparent;
  border: none;
  height: 100%;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 35px;
}

.sid-select-button:disabled {
  cursor: not-allowed;
}
