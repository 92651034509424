/**
 * TODO - preamble
 */
// TODO hyphen-case vs camelCase - be consistent
$black: #000;
$white: #fff;
$red: #e71e10;
$dark-red: #e12046;
$sheer-blue: #26c1db;
$light-blue-gray: #acb4c6;
$blue-gray: darken($light-blue-gray, 30%);
$dark-blue-gray: darken($blue-gray, 40%);
$light-gray: #f1f3f6;
$gray: darken($light-gray, 20%);
$sheer-dark-blue: #192650;

// TODO spelling grey/gray
$dark-gray: #666;
$darker-gray: #444;

$blue-link: #006ded;

$base-z-index: 1;

$border-width: 1px;
$box-shadow: 0 2px 30px rgb(25 38 80 / 10%);

$smallPadAmt: 0.6rem;
$mediumPadAmt: 20px;
$largePadAmt: 50px;
$xlPadAmt: 110px;

$smallMarginAmt: 10px;
$mediumMarginAmt: 30px;

$gutterWidth: $smallPadAmt;

$smallBorderRadius: 2px;
$standardBorderRadius: 5px;

$listItemHeight: 3.5rem;
$listMessageHeight: 2.6rem;
