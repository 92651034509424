.sid-discharge-date__inputs {
  display: flex;
}

.sid-discharge-date__month,
.sid-discharge-date__day {
  margin-right: $gutterWidth;
}

.sid-discharge-date__month,
.sid-discharge-date__day,
.sid-discharge-date__year {
  box-sizing: content-box;
}

.sid-discharge-date__month {
  min-width: 130px;
  width: auto;
}

.sid-discharge-date__day {
  width: calc(var(--sheerid-body-line-height) * 1.6rem);
}

.sid-discharge-date__year {
  width: calc(var(--sheerid-body-line-height) * 2.1rem);
}
