@import "./sheerid-variables";

// ====================================== Helpers =================================
.sid-h-link-like {
  color: $sheer-blue;
  cursor: pointer;
  display: inline;

  &:focus {
    outline: none;
  }

  &:hover {
    text-decoration: underline;
  }
}

.sid-h-screen-reader-only {
  height: 1px;
  left: -10000px;
  overflow: hidden;
  position: absolute;
  top: auto;
  width: 1px;
}

.sid-h-jumbo-text {
  font-size: var(--sheerid-font-size-title);
  font-weight: 800;
}

.sid-h-small-text {
  font-size: var(--sheerid-font-size-small);
}

.sid-h-bold-text {
  font-weight: 700;
}

.sid-h-label {
  background-color: $light-gray;
  border: $light-blue-gray 1px solid;
  border-radius: $smallBorderRadius;
  color: $blue-gray;
  padding: $smallPadAmt $mediumPadAmt;
  text-transform: uppercase;
}

.sid-h-btn-link-like {
  background: none;
  border: none;
  color: $sheer-blue;
  cursor: pointer;
  display: inline;
  padding: 0;

  &:focus {
    outline: none;
  }

  &:hover {
    text-decoration: underline;
  }
}
