.sid-organization-list {
  position: relative;
}

.sid-organization-list__menu {
  background-color: $white;
  border: 1px solid $light-blue-gray;
  border-top: 0;
  box-sizing: border-box;
  min-height: $listItemHeight + 1;
  overflow: auto;
  position: absolute;
  width: 100%;
  z-index: $base-z-index;
}

.sid-organization-list__item {
  background-color: $white;
  box-sizing: border-box;
  color: $black;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: auto !important;
  justify-content: center;
  min-height: $listItemHeight;
  min-width: 100%;
  padding: $smallPadAmt;
  word-wrap: break-word;
}

.sid-organization-list__item:hover,
.sid-organization-list__item--highlighted {
  background-color: $light-gray;
}

.sid-organization-list__message {
  align-content: center;
  color: $light-blue-gray;
  display: flex;
  flex-direction: column;
  height: $listMessageHeight;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.sid-organization-list__spinner-container {
  margin: $mediumMarginAmt 0;
}
