.sid-layout {
  background-color: var(--sheerid-landing-background-color);
  background-image: var(--sheerid-landing-background-image-url);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;

  &__container {
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
  }

  &__form-container {
    background-color: var(--sheerid-form-background-color);
    height: 100%;
    overflow-y: auto;
    padding: 1.5rem;
  }

  &__form {
    height: 100%;
  }

  &--center {
    & .sid-layout {
      &__container {
        align-items: center;
        padding: 1rem;
      }
    }
  }

  &--splitRight,
  &--fullRight {
    align-items: center;
    justify-content: center;

    & .sid-layout {
      &__container {
        justify-content: flex-end;
      }

      &__form-container {
        align-items: center;
        display: flex;
        justify-content: center;
        width: 50%;
      }
    }
  }

  &--fullRight {
    & .sid-layout {
      &__container {
        align-items: center;
        padding: 1rem;
      }

      &__form-container {
        margin: 4rem;
        width: auto;
      }

      &__content {
        color: var(--sheerid-landing-primary-font-color);
        display: flex;
        flex-direction: column;
        padding: 0 0 10rem 4rem;
        width: 50%;

        & .sid-header {
          .sid-landing-header {
            color: inherit;
          }

          &__title > h1 {
            font-size: calc(var(--sheerid-font-size-body) * 4);
            line-height: var(--sheerid-heading-line-height);
            margin-bottom: 0.5rem;
            text-align: left;
          }

          &__subtitle {
            font-size: var(--sheerid-font-size-title);
            margin-bottom: 0.5rem;
            text-align: left;
          }
        }

        & .sid-header__how-verifying-works {
          text-align: left;

          & .sid-how-verify-works {
            &__tooltip {
              background: $white;
              color: $black;
              font-size: calc(var(--sheerid-font-size-body) * 0.75);
            }

            &__caret {
              border-bottom-color: $white;
            }
          }
        }
      }
    }

    .sid-personal-info-header:not(.sid-layout__content .sid-personal-info-header) {
      display: none;
    }
  }
}

@media screen and (width <= 810px) {
  .sid-layout {
    // 100vh is required at full sizes, but breaks the updateHeight messaging
    // from embeds.
    height: 100%;

    &__form-container {
      padding: 0;
    }

    &--center {
      & .sid-layout {
        &__form-container {
          margin: 0;
        }
      }
    }

    &--splitRight {
      & .sid-layout {
        &__form-container {
          width: 60%;
        }
      }
    }

    &--fullRight {
      & .sid-layout {
        &__form-container {
          margin: 0;
        }

        &__container {
          justify-content: center;
        }

        &__content {
          display: none;
        }
      }

      .sid-personal-info-header:not(.sid-layout__content .sid-personal-info-header) {
        display: block;
      }
    }
  }
}

@media screen and (width <= 428px) {
  .sid-layout {
    &__container {
      padding: 0 !important;
    }

    &__form-container {
      height: 100% !important;
      width: 100% !important;
    }
  }
}
