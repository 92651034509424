@function px($int) {
  @return #{$int}px;
}

$tooltip-width: 300;
$caret-width: 15;

.sid-how-verify-works {
  display: inline-block;
  position: relative;
  text-align: start;
}

.sid-how-verify-works__container {
  bottom: px($caret-width * -1);
  height: px($caret-width);
  left: -10%;
  position: absolute;
  width: px($tooltip-width);
}

.sid-how-verify-works__caret {
  border-bottom: px($caret-width) solid $white;
  border-left: px($caret-width) solid transparent;
  border-right: px($caret-width) solid transparent;
  bottom: 0;
  height: 0;
  left: px(calc($tooltip-width / 2) - ($caret-width));
  position: absolute;
  width: 0;
  z-index: $base-z-index + 2;
}

.sid-how-verify-works__tooltip {
  background: $white;
  border-radius: $standardBorderRadius;
  box-shadow: $box-shadow;
  box-sizing: border-box;
  left: 0;
  padding: $gutterWidth * 2;
  position: absolute;
  top: px($caret-width);
  width: px($tooltip-width);
  z-index: $base-z-index + 1;
}
