.sid-complete-auth {
  &__manually-verify-button {
    border: none;
  }
}

.auth-code {
  padding-bottom: 40px;

  &__input {
    background: #fff;
    border: 1px solid #000;
    border-radius: 4px;
    caret-color: transparent;
    font-size: 3rem;
    height: 60px;
    margin: 4px;
    text-align: center;
    width: 100%;
  }

  &__container {
    display: flex;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
  }

  &__input-error {
    border: 1px solid #f00;
  }

  &__error-container {
    display: flex;
    padding-left: 14px;
    padding-top: 10px;
  }

  &__error-text {
    color: #f00;
    font-size: var(--sheerid-font-size-subtitle);
    padding-left: 5px;
    padding-top: 2px;
  }
}
