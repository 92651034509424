.sid-spinner {
  display: inline-block;
  height: 64px;
  position: relative;
  width: 64px;

  &__frame {
    animation: sid-spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border: 6px solid $sheer-blue;
    border-color: $sheer-blue transparent transparent transparent;
    border-radius: 50%;
    box-sizing: border-box;
    display: block;
    height: 51px;
    margin: 6px;
    position: absolute;
    width: 51px;
  }

  &__frame:nth-child(1) {
    animation-delay: -0.45s;
  }

  &__frame:nth-child(2) {
    animation-delay: -0.3s;
  }

  &__frame:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes sid-spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
