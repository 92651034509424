// Base rules
:root {
  --sheerid-font-family-sans: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  --sheerid-font-family-serif: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
  --sheerid-font-family-mono: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --sheerid-font-size-body: 1rem;
  --sheerid-font-size-title: calc(var(--sheerid-font-size-body) * 1.5);
  --sheerid-font-size-subtitle: calc(var(--sheerid-font-size-body) * 1.25);
  --sheerid-font-size-medium: calc(var(--sheerid-font-size-body) * 0.875);
  --sheerid-font-size-small: calc(var(--sheerid-font-size-body) * 0.75);
  --sheerid-heading-line-height: 1.2; // Used on step titles and field labels. if you use this with calc(), add `rem` unit there, not here
  --sheerid-body-line-height: 1.5; // Used everywhere else. if you use this with calc(), add `rem` unit there, not here
  --sheerid-font-family-title: var(--sheerid-font-family-sans);
  --sheerid-font-family-subtitle: var(--sheerid-font-family-sans);
}
