.sid-field {
  &__label {
    font-weight: 600;
  }

  &__label-name {
    white-space: nowrap;
  }

  &__label-with-explanation {
    display: flex;
  }

  &__label-explanation {
    color: var(--sheerid-form-helper-font-color);
    font-size: var(--sheerid-font-size-medium);
    font-weight: normal;
    margin-left: $gutterWidth * 2;
  }
}

.sid-text-input {
  background-color: #fff;
  border: 1px solid $light-blue-gray;
  box-sizing: border-box;
  color: #000;
  cursor: auto;
  font-weight: 400;
  margin: 0;
  padding: var(--sheerid-font-size-medium);
  transition: all 0.15s;
}

.sid-text-input::placeholder {
  color: #717171;
  opacity: 1;
}

// by default, hide placeholders. Override this rule to show them using css-only
.sid-hidden-placeholder::placeholder {
  color: transparent;
}

.sid-text-input:focus {
  border-color: $black;
  outline: none;
}

.sid-text-input:disabled {
  background-color: $light-gray;
  cursor: not-allowed;
}

.sid-field-error {
  color: $red;
  margin-top: 5px;
}

.sid-text-input--error {
  border-color: $red;
}

.sid-text-input--error:focus {
  border-color: $red;
}

.sid-field-notice {
  margin-top: 5px;
}

.sid-select-input {
  background-color: #fff;
  border: 1px solid $light-blue-gray;
  box-sizing: border-box;
  color: #000;
  cursor: default;
  font-weight: 400;
  padding: 10px 12px;
  transition: all 0.15s;
}

.sid-select-input--error {
  border-color: $red;
}

.sid-checkbox {
  display: inline-block;
  width: 5%;
}

.sid-checkbox__input-like {
  border: 1px solid $black;
  height: 15px;
  left: 0;
  position: absolute;
  top: 0;
  transition: 0.2s;
  width: 15px;
}

.sid-checkbox__input-like--error {
  border: 1px solid $red;
}

.sid-checkbox__input-like::after {
  border: solid #fff;
  border-width: 0 2px 2px 0;
  content: "";
  display: none;
  height: 11px;
  left: 4px;
  position: absolute;
  top: 0;
  transform: rotate(45deg);
  transition: 0.2s;
  width: 4px;
}

.sid-checkbox__input {
  cursor: pointer;
  margin-top: 4px;
  opacity: 0;
  position: absolute;
  top: 0;
}

.sid-checkbox__input:checked ~ .sid-checkbox__input-like {
  background-color: #555;
  border: 1px solid $black;
  transition: 0.2s;
}

.sid-checkbox__input:focus ~ .sid-checkbox__input-like {
  background-color: #e5e5e5;
  border: 1px solid $black;
}

.sid-checkbox__input:checked:focus ~ .sid-checkbox__input-like {
  background-color: #555;
}

.sid-checkbox__input:checked ~ .sid-checkbox__input-like::after {
  display: block;
}

.sid-checkbox__label {
  cursor: pointer;
  display: inline-block;
  font-size: var(--sheerid-font-size-small);
  font-weight: normal;
  padding-left: 10px;
  text-transform: none;
  user-select: none;
  width: 90%;
}

// Newer-style layout for FormFieldCheckbox, uses flexbox
.sid-checkbox-flex {
  align-items: flex-start;
  display: flex;

  .sid-checkbox__input {
    left: -9999px;
    position: absolute;
  }

  .sid-checkbox__label {
    width: initial;
  }

  .sid-checkbox__input-like {
    cursor: pointer;
    display: block;
    left: initial;
    position: relative;
    top: initial;
  }
}

.sid-radio-grid {
  display: grid;
  gap: $gutterWidth;
}

.sid-radio__input {
  align-items: center;
  display: flex;
  gap: $gutterWidth;
}

.sid-radio__input > input {
  margin: 0;
}

.sid-radio__inputs--error {
  outline: 1px solid $red;
}
