.sid-id-check-step-card {
  background-color: $white;
  border: 1px solid $light-blue-gray;
  border-radius: 0.75rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  padding: 1rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  &--highlighted {
    border: 1px solid $blue-gray;
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__icon {
    height: 3rem;
    margin-bottom: 0.5rem;
    width: 3rem;
  }

  &__step {
    background-color: $light-gray;
    border-radius: 1.5rem;
    color: $dark-gray;
    height: fit-content;
    padding: 0.5rem 0.75rem;

    &--highlighted {
      background-color: $white;
      border: 1px solid $black;
      color: $black;
    }
  }

  &__title {
    font-weight: 700;
  }
}
