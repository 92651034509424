.sid-form-wrapper {
  background-color: var(--sheerid-form-background-color);
  color: var(--sheerid-form-primary-font-color);
  font-family: var(--sheerid-font-family-body);
  font-size: var(--sheerid-font-size-body);
  line-height: var(--sheerid-body-line-height);
  min-width: 250px;
  /* stylelint-disable property-no-unknown */
  // Useful for printing background colors (used by internal mockup generator)
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact; // autoprefixer doesn't autoprefix this property
  /* stylelint-enable property-no-unknown */
}

:where(.sid-form-wrapper) input,
:where(.sid-form-wrapper) textarea,
:where(.sid-form-wrapper) button {
  font-family: inherit;
  font-size: inherit;
}
