.sid-upload-wrap {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.sid-submit {
  display: flex;

  &__continue,
  &__cancel {
    width: 33%;
  }
}

.sid-dropzone-wrap {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;

  &--link {
    color: $blue-link;
  }
}

.sid-dropzone-wrap__dropzone {
  align-items: center;
  background-color: $light-gray;
  cursor: pointer;
  display: flex;
  height: 60px;
  width: 100%;
}

.sid-dropzone-wrap__instructions {
  color: $sheer-dark-blue;
}

.sid-file-list {
  width: 100%;

  &__file {
    align-items: center;
    background: $light-gray;
    display: flex;
    margin: 5px 0;
  }

  &__remove-btn {
    cursor: pointer;
    height: 15px;
    margin-left: 5px;
    width: 15px;
  }

  &__remove-btn::after {
    color: $blue-gray;
    content: "\d7";
    font-size: var(--sheerid-font-size-subtitle);
    font-weight: 100;
    left: 0;
    position: relative;
    top: -2px;
  }

  &__thumbnail {
    height: 34px;
    object-fit: cover;
    width: 34px;
  }

  &__no-thumbnail {
    background: #fff;
    display: flex;
    height: 34px;
    justify-content: center;
    width: 34px;
  }

  &__name {
    flex: 1;
    overflow: hidden;
    padding: $smallPadAmt;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.sid-upload-wrap__accepted-types {
  color: $dark-gray;
}

.sid-upload-info {
  background-color: $light-gray;
  color: $sheer-dark-blue;
  padding: $mediumPadAmt;
}

.sid-upload-rejection-list {
  background-color: $light-gray;
  color: $sheer-dark-blue;
  display: flex;
  flex-wrap: wrap;
  padding: $mediumPadAmt;
}

.sid-upload-rejection-list__li {
  margin: 0 $mediumPadAmt $smallPadAmt 15px;
}

.sid-dropzone-wrap__accepted-types {
  color: $blue-gray;
  font-size: var(--sheerid-font-size-small);
}

.sid-add-more-files {
  align-items: center;
  color: $blue-gray;
  display: flex;

  &:hover {
    cursor: pointer;
  }

  &__input {
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    width: 0.1px;
    z-index: -1;
  }

  &__label {
    align-items: center;
    color: $blue-gray;
    display: flex;
    font-size: var(--sheerid-font-size-small);
    padding: 4px;
  }

  &__image {
    height: 10px;
    margin-right: 5px;
    width: 10px;
  }

  &--selectable {
    cursor: pointer;
  }
}

.sid-field-error__doc-upload {
  font-size: var(--sheerid-font-size-subtitle);
  margin-bottom: $smallPadAmt;
}

/**
 * BELOW ARE THE REDESIGN `doc-upload-redesign` flagged styles
*/
.sid-doc-upload-redesign-container {
  .sid-upload-rejection__wrapper {
    background-color: $light-gray;
    color: $sheer-dark-blue;
    font-size: var(--sheerid-font-size-subtitle);
    padding: $mediumPadAmt;
  }

  .sid-header__subtitle-doc-upload-rejected {
    color: $dark-red;
    font-size: var(--sheerid-font-size-subtitle);
    font-weight: bold;
  }

  .sid-header__subtitle-old-font-size {
    font-size: 1rem !important;
    font-weight: normal;
  }

  .sid-upload-rejection-list {
    background-color: $light-gray;
    color: $sheer-dark-blue;
    display: flex;
    flex-wrap: wrap;
    font-size: var(--sheerid-font-size-subtitle);
    padding: 0;
  }

  .sid-file-list {
    display: grid;
    gap: 0.25rem;
    grid-template-columns: 50% 50%;
    width: 99%;

    &__file {
      background: $white;
      border: 1px solid #cbd5e1;
      border-radius: 0.25rem;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      padding: 0;
      position: relative;
    }

    &__remove-btn {
      background: #475569;
      border-radius: 50%;
      color: #fff;
      cursor: pointer;
      height: 24px;
      position: absolute;
      right: 8px;
      text-align: center;
      top: 8px;
      width: 24px;
    }

    &__remove-btn::after {
      color: #fff;
      content: "\d7";
      font-size: var(--sheerid-font-size-subtitle);
      font-weight: 400;
      left: 0;
      position: relative;
    }

    &__thumbnail-wrapper {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      place-content: center;
    }

    &__thumbnail {
      height: auto;
      object-fit: unset;
      width: 100%;
    }

    &__no-thumbnail {
      background: #fff;
      display: flex;
      height: 34px;
      justify-content: center;
      width: 34px;
    }

    &__name-wrapper {
      align-items: center;
      display: flex;
      flex-direction: row;
      font-size: var(--sheerid-font-size-small);
      font-weight: normal;
      justify-content: space-between;
      padding: $smallPadAmt 0 $smallPadAmt $smallPadAmt;
      width: 95%;
    }

    &__name {
      flex: 1;
      overflow: hidden;
      padding: 4px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .sid-dropzone-wrap {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  }

  .sid-dropzone-wrap__dropzone {
    align-items: center;
    background-color: $white;
    border-radius: 0.25rem;
    cursor: pointer;
    display: flex;
    flex-basis: 0;
    flex-direction: column;
    flex-grow: 1;
    height: 8rem;
    justify-content: center;
    width: unset;
  }

  .sid-dropzone-wrap__instructions {
    color: $sheer-dark-blue;
    font-size: var(--sheerid-font-size-subtitle);
  }

  .sid-uploadzone__button {
    background-color: #475569;
    border: none;
    border-radius: 0.25rem;
    color: $white;
    font-weight: 500;
    height: 3rem;
    width: 100%;

    &:hover {
      cursor: pointer;
    }
  }

  .sid-uploadzone__menu {
    background-color: $white;
    border: 1px solid $light-blue-gray;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    list-style-type: none;
    margin-top: 3px;
    padding-left: 0;
    position: absolute;
    width: 200px;
    z-index: 50;
  }

  .sid-uploadzone__icon {
    height: 24px;
    width: 24px;
  }

  .sid-add-more-files {
    display: block;
    width: 100%;
  }

  .sid-submit__continue {
    width: 100%;
  }

  .sid-header__title {
    text-align: start;
  }

  .sid-doc-upload-requirements__definition {
    font-weight: normal;
    margin: 1rem 0;
  }

  .sid-acceptable-document-types {
    list-style-position: inside;
    margin: 0 0 0.5rem;
    padding: 0;

    &__item {
      margin: 0.5rem 0;
    }
  }

  .sid-header_instructions {
    text-align: justify;
  }

  .display-block {
    display: block;
  }

  .thumbnail_wrapper_width {
    width: 100%;
  }

  .thumbnail_wrapper {
    background-color: $light-gray;
    border-radius: 0;
    color: #3268a8;
    min-height: 10rem;
  }

  .thumbnail_wrapper-text {
    color: #3268a8;
  }

  .sid-submit-doc-upload_pb {
    padding-bottom: 1rem;
  }

  .sid-dropzone-wrap__dropzone_height {
    height: 4rem;
  }

  .sid-field-error-redesign__doc-upload {
    background-color: #ffebe6;
    border-radius: 0.25rem;
    color: black;
    font-size: var(--sheerid-font-size-subtitle);
    font-weight: bold;
    margin: $mediumPadAmt 0 $mediumPadAmt 0;
    padding: $mediumPadAmt;

    &__span {
      font-size: var(--sheerid-font-size-small);
      font-weight: normal;
      padding-top: $smallPadAmt;
    }
  }
}
