.sid-loading-screen {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 500px;
  width: 100%;

  .sid-loading-screen__message-header {
    font-size: var(--sheerid-font-size-subtitle);
    font-weight: bold;
    text-align: center;
  }

  .sid-loading-screen__message-subhead {
    color: #999;
    text-align: center;
  }
}
