.sid-select-list {
  position: relative;
}

.sid-select-list__menu {
  background-color: $white;
  border: 1px solid $light-blue-gray;
  box-sizing: border-box;
  margin-top: 7px;
  min-height: $listItemHeight + 1;
  position: absolute;
  width: 100%;
  z-index: $base-z-index;
}

.sid-select-list__item {
  box-sizing: border-box;
  color: $black;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: auto !important;
  justify-content: center;
  max-width: calc(100% - (#{$smallPadAmt} * 2));
  min-height: $listItemHeight;
  min-width: 100%;
  padding: $smallPadAmt;
  word-wrap: break-word;
}

.sid-select-list__item--highlighted {
  background-color: $light-gray;
}

.sid-select-list__message {
  align-content: center;
  color: $light-blue-gray;
  display: flex;
  flex-direction: column;
  height: $listMessageHeight;
  justify-content: center;
  text-align: center;
  width: 100%;
}
